import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { AuthProvider } from 'context/AuthContext';
import { ConfigProvider } from 'context/ConfigContext';
import { HelmetProvider } from 'react-helmet-async';
import store from 'redux/store';
import { indexRoutes } from 'routes';
import { ScrollReset } from './components';
import Route from './routes/AuthRoute';
import theme from './theme';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <Router>
      <ScrollReset />
      <AuthProvider>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route {...prop} key={key} />;
          })}
        </Switch>
      </AuthProvider>
    </Router>
  );
}

ReactDOM.render(
  <div>
    <GoogleOAuthProvider clientId="3151640327-fgqvusl6vjphe80tq0qvpve6sbjkc81s.apps.googleusercontent.com">
      <ToastContainer />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HelmetProvider>
            <ConfigProvider>
              <App />
            </ConfigProvider>
          </HelmetProvider>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  </div>
  ,
  document.getElementById('root'), // eslint-disable-line
);