/*
TODO tomorrow
cache images (more specifically unprocessed images, unsure if processed needs it)
fix loading text
renaming/commenting/formatting code
better formating, maybe an overlay? or side bar?
processed image data
remove DZI mentions (new algorithms don't use DZI)
temp coment
*/

import React, { useEffect, useRef, useState} from 'react';
import OpenSeadragon from 'openseadragon';

const ImageViewer = ({ selectedProcessedImage }) => {
    const [showTiff, setShowTiff] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const viewerRef = useRef(null);

    useEffect(() => {
        if (selectedProcessedImage) {
            console.log(selectedProcessedImage);
            setIsLoading(true);

            const { uri, stain_list, sample_name } = selectedProcessedImage;
            let imageUrl;

            if (showTiff) {
                imageUrl = `${uri.split('/Stained/')[0]}/unstained/${sample_name}`;
            } else {
                imageUrl = `${uri}/vIHC ${stain_list}/${sample_name.split('.').slice(0, -1).join('.')}. .dzi`;
            }

            if(viewerRef.current) {
                viewerRef.current.destroy();
                viewerRef.current = null;
            }

            const viewerElement = document.getElementById('openseadragon-viewer');
            if (viewerElement) {
                viewerRef.current = OpenSeadragon({
                    id: 'openseadragon-viewer',
                    prefixUrl: 'openseadragon-images/',
                    tileSources: showTiff ? undefined : imageUrl,
                    animationTime: 0.5,
                    blendTime: 0.1,
                    minZoomLevel: 1,
                    minZoomImageRatio: 1,
                    visibilityRatio: 1,
                    zoomPerScroll: 2,
                    constrainDuringPan: true,
                    showHomeControl: false,
                    showZoomControl: false,
                    showNavigationControl: false,
                    showSequenceControl: false,
                    immediateRender: true, // might remove
                    timeout: 600000,
                });

                if (showTiff) {
                    if (window.GeoTIFFTileSource && (!viewerRef.current.tileSources || viewerRef.current.tileSources.length === 0)) {
                        window.GeoTIFFTileSource(OpenSeadragon);
                        let tiffTileSources = OpenSeadragon.GeoTIFFTileSource.getAllTileSources(imageUrl, { logLatency: true });
                        tiffTileSources.then((ts) => {
                            viewerRef.current.open(ts);
                            viewerRef.current.addHandler('tile-drawn', () => {
                                setIsLoading(false);
                            });
                        });
                    } else {
                        console.error("GeoTIFFTileSource not loaded or available");
                        setIsLoading(false);
                    }
                } else {
                    viewerRef.current.addHandler('open', () => {
                        setIsLoading(false);
                    });
                }
            }
        }
    }, [selectedProcessedImage, showTiff]);

    const handleToggle = () => {
        setShowTiff((prev) => !prev);
    };

    const handleDownload = async () => {
        const { uri, sample_name } = selectedProcessedImage;
        if (showTiff) {
            const link = document.createElement('a');
            link.href = `${uri.split('/Stained/')[0]}/unstained/${sample_name}`;
            link.download = sample_name;
            link.click();
        } else {
            console.log("Unable to download DZI images");
        }

    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div id='openseadragon-viewer' style={{ height: '100%', width: '100%', aspectRatio: '1/1' }}></div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <label>
                    <input type="checkbox" checked={showTiff} onChange={handleToggle} disabled={isLoading}/>
                    ShowTiff
                </label>
                <button onClick={handleDownload} disabled={isLoading}>Download Image</button>
            </div>
        </div>
    );
};

export default ImageViewer;