import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import * as React from 'react';
import InputFile from '../../uploader/InputForm.js';
import '../dashboard.css'
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const UploadForm = (props) => {
    const { algoList } = props;
    const [imageDesc, setImageDesc] = React.useState("");
    const [tags, setTags] = React.useState("");
    const [imageDropdownName, setImageDropdownName] = React.useState([]);
    const [imageSizeValue, setImageSizeValue] = React.useState([]);
    const imageSizeDropdown = [
        {   
            id: '001',
            sizeName: '1.2mm ✖️ 1.2mm',
            sizeValue: '1.2 , 1.2'
        }
    ];

    React.useEffect(() => {
        if (!!algoList && algoList.length > 0) {
            const algos = algoList;
            let names = [];
            _.forEach(algos, (algo) => {
                names.push(algo.algo_name)
            })
        }
    }, [algoList]);

    const handleChange = (event: SelectChangeEvent) => {
        setImageDropdownName(event.target.value.sizeName);
        setImageSizeValue(event.target.value.sizeValue)
      };

    return (
        <Box component="fieldset" className='fieldset'
            sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
                paddingLeft: '10px',
            }}
            noValidate
            autoComplete="off"
        >
            <Box className='legend' component="legend">Image Information</Box>

            <div className='w95'>
                <Typography variant='h6' className='headtxtcss fntb141'>
                    Description :
                </Typography>
                <TextField
                    id="outlined-disabled"
                    label="Description"
                    onChange={(event) => setImageDesc(event.target.value)}
                />

                <Typography variant='h6' className='headtxtcss fntb141'>
                    Tag :
                </Typography>
                <TextField
                    id="outlined-multiline-static"
                    label="Tags (X, Y..)"
                    multiline
                    onChange={(event) => setTags(event.target.value)}
                />
                <div className='wrapsec12'>
                    <Typography variant='h6' className='headtxtcss fntb14'>
                        Size :
                    </Typography>
                    <FormControl className='selectbox'>
                        <Select
                        labelId='demo-simple-select-helper-label'
                        id='demo-simple-select-helper'
                        value={imageDropdownName}
                        onChange={data => handleChange(data)}
                        className='wh100'
                        displayEmpty
                        renderValue={imageDropdownName => {
                            if (imageDropdownName.length === 0) {
                                return (
                                    <em style={{ fontStyle: 'normal' }}>Select Image Size</em>
                                )
                                }
                                return imageDropdownName
                            }}
                        >
                            {imageSizeDropdown && imageSizeDropdown.length > 0 && imageSizeDropdown.map((item) => (
                                <MenuItem value={item} key={item.id}>{item.sizeName}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                </div>
                <InputFile handleCallBack={props.handleCallBack} imageDesc={imageDesc} tags={tags} imageSizeValue={imageSizeValue}></InputFile>
            </div>
        </Box>
    );
}

export default UploadForm
